import { service, serviceNoWarning } from './http' // 使用实例

const get = function (url, params) {
    return service({
        url: url,
        method: 'GET',
        params
    })
}
const getNoWarning = function (url, params) {
    return serviceNoWarning({
        url: url,
        method: 'GET',
        params
    })
}
const post = function (url, params, data) {
    return service({
        url: url,
        method: 'POST',
        data: data,
        params: {}
    })
}
// 请求用户信息
export function requestUser() { return getNoWarning('/api/v1/login/user', null) }
// 应用请求转发
export function requestDirect(appkey) { return getNoWarning('/api/v1/login/direct/' + appkey, null) }
// 请求新的ticket
export function requestTicket() { return get('/api/v1/login/ticket', null) }
// 根据钉钉返回code登录
export function loginByCode(params) { return get('/api/v1/login/qrcode', params) }
// 退出
export function loginQuit() { return get('/api/v1/login/quit', null) }

// 姓名模糊查询用户
export function searchUserLikeName(params) { return get('/api/v1/org/like', params) }
// 查询完整组织架构树
export function searchOrgTree() { return get('/api/v1/org/tree', null) }
// 查询下级直属组织
export function searchSubOrg(params) { return get('/api/v1/org/sub', params) }

// 同步全量数据
export function syncAll() { return get('/api/v1/sync/task/all', null) }
// 同步全量AD数据
export function syncAllAd() { return get('/api/v1/sync/task/ad', null) }
// 同步单个用户
export function syncUserSingle(userId) { return get('/api/v1/sync/task/user/' + userId, null) }
// 同步单个部门
export function syncDeptSignle(deptId) { return get('/api/v1/sync/task/dept/' + deptId, null) }
// 模拟同步事件
export function syncEvent(param) { return get('/api/v1/sync/task/event', param) }


// 查询超级管理员列表
export function aboveSearch() { return get('/api/v1/abover/list', null) }
// 添加超级管理员
export function aboverAdd(data) { return post('/api/v1/abover/add', null, data) }
// 删除超级管理员
export function aboverRemove(data) { return post('/api/v1/abover/remove', null, data) }


// 查询应用列表
export function appSearch() { return get('/api/v1/app/mylist', null) }
// 应用申请
export function appAsk(data) { return post('/api/v1/app/ask', null, data) }
// 应用编辑
export function appEdit(appkey, data) { return post('/api/v1/app/edit/' + appkey, null, data) }
// 应用审批
export function appApproval(appkey) { return get('/api/v1/app/approval/' + appkey, null) }
// 应用停用
export function appDisable(appkey) { return get('/api/v1/app/disbale/' + appkey, null) }
// 申请启用
export function appEnable(appkey) { return get('/api/v1/app/enable/' + appkey, null) }
// 申请重置
export function appReset(appkey) { return get('/api/v1/app/reset/' + appkey, null) }
// 获取应用详情
export function appGet(appkey) { return get('/api/v1/app/get/' + appkey, null) }



// 查询应用管理员列表
export function ownerSearch(appkey) { return get('/api/v1/owner/list/' + appkey, null) }
// 添加应用管理员
export function ownerAdd(appkey, data) { return post('/api/v1/owner/add/' + appkey, null, data) }
// 移除应用管理员
export function ownerRemove(appkey, data) { return post('/api/v1/owner/remove/' + appkey, null, data) }

// 查询角色列表
export function roleSearch(appkey, data) { return post('/api/v1/role/list/' + appkey, null, data) }
// 增加角色
export function roleAdd(appkey, data) { return post('/api/v1/role/save/' + appkey, null, data) }
// 增加角色
export function roleGet(roleId) { return get('/api/v1/role/get/' + roleId, null) }
// 删除角色
export function roleRemove(appkey, data) { return post('/api/v1/role/remove/' + appkey, null, data) }
// 查询资源
export function bindList(roleId) { return get('/api/v1/role/bind/list/' + roleId, null) }
// 绑定资源
export function bindSave(roleId, data) { return post('/api/v1/role/bind/save/' + roleId, null, data) }
// 查询成员
export function memberList(roleId) { return get('/api/v1/role/member/list/' + roleId, null) }
// 绑定成员
export function memberSave(roleId, data) { return post('/api/v1/role/member/save/' + roleId, null, data) }
// 绑定成员
export function memberRemove(roleId, data) { return post('/api/v1/role/member/remove/' + roleId, null, data) }

// 查询资源列表
export function resourceTree(appkey) { return get('/api/v1/resource/tree/' + appkey, null) }
// 增加资源
export function resourceAdd(appkey, data) { return post('/api/v1/resource/save/' + appkey, null, data) }
// 删除资源
export function resourceRemove(appkey, data) { return post('/api/v1/resource/remove/' + appkey, null, data) }
// 移动资源
export function resourceMove(appkey, pid, rid) { return get('/api/v1/resource/move/' + appkey + "/" + pid + "/" + rid, null) }

// 事件通知日志查询
export function eventSearch(data) { return post('/api/v1/event/search', null, data) }

// 枚举类型
// 钉钉通知事件类型
export function setDingEvent() { return get('/api/v1/set/enums/dingevent', null) }

// 所有类型
export function setType() { return get('/api/v1/set/enums/type', null) }
// 类型配置项查询
export function setAllByType(type) { return get('/api/v1/set/all/' + type, null) }
// 基础配置项查询=新增类型
export function setNewType(data) { return post('/api/v1/set/save', null, data) }
// 禁用类型=删除
export function setDisableType(setid) { return get('/api/v1/set/disable/'+setid, null) }
// 基础配置项更新=编辑
export function setSaveType(setid, data) { return post('/api/v1/set/update/'+setid, null, data) }

// // 所有类型
// export function setType() { return get('/api/v1/set/enums/type', null) }
// // 类型配置项查询
// export function setAllByType(type) { return get('/api/v1/set/all/' + type, null) }
