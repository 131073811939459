import axios from "axios";
import { ElMessage } from "element-plus";

// 请求配置
const base = {
  // baseURL: process.env.VUE_APP_BASEURL,
  // baseURL: process.env.VUE_APP_BASEURL_TEST, // 测试环境
  baseURL: "/", // vue.config.js设置CORS跨域后，将此处设置为 /
  timeout: 300000, // 请求超时时间
};
// 请求发送拦截操作
const requestConfig = function(config) {
  var token = window.localStorage.getItem("token");
  var userInfo = window.localStorage.getItem("userInfo");
  if (userInfo && token) {
    // console.log(config);
    config.headers.common["x-authrization"] = token;
  }
  return config;
};
// 请求发送错误拦截
const requestError = function(error) {
  return Promise.reject(error);
};

// 创建axios实例
const service = axios.create(base);
// request 拦截器
service.interceptors.request.use(requestConfig, requestError);
// response 拦截器
service.interceptors.response.use(
    response => {
        if (response.data.code === 0) {
            // ElMessage.success('请求成功')
            return response.data
        }
        ElMessage.error(response.data.alertMsg);
        return Promise.reject(response.data)
    },
    error => {
        ElMessage.error(error.alertMsg || '请求错误');
        return Promise.reject(error)
    }
);

// 创建axios实例不需要ElMessage
const serviceNoWarning = axios.create(base);
// request 拦截器
serviceNoWarning.interceptors.request.use(requestConfig, requestError);
// response 拦截器
serviceNoWarning.interceptors.response.use(
  (response) => {
    if (response.data.code === 0) {
      return response.data;
    }
    return Promise.reject(response.data);
  },
  (error) => {
    return Promise.reject(error);
  }
);
export { service, serviceNoWarning };
